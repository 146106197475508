<template>
  <div class="mt-12">
    <div class="font-bold">{{ $t('lbl_members') }}</div>
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-6" v-for="mem in members" :key="mem.id + 'mem'">
        <div class="dropdown">
          <div
            type="button"
            id="dropdownMenuButton"
            :data-toggle="isAdmin?'dropdown':''"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div
              class="d-flex align-items-center justify-content-between item-doctor p-0 bg-white pl-3"
              :class="isAdmin?'form-select-arrow':''"
            >
              <template v-if="mem && mem.user">
                <div class="d-flex align-items-center" :set="(user = mem.user)">
                  <span
                    v-if="user && user.avatar"
                    class="avatar avatar-sm avatar-rounded mr-2 border"
                    :style="
                        'background-image: url(' +
                        getImageURL(user.avatar) +
                        ')'
                      "
                  ></span>
                  <span
                    v-if="user && !user.avatar"
                    class="avatar avatar-sm avatar-rounded mr-2 border"
                  >{{ user && user.name ? user.name.charAt(0) : "" }}</span>
                  <div>
                    <p class="robo-14-400 txt-black mb-0 max-line-1">
                      {{ user&& user.name? user.name : "" }}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="ml-2"
                        v-if="mem.type === 1"
                      >
                        <path
                          d="M6.58579 9.41452C7.36684 10.1956 8.63316 10.1956 9.41421 9.41452C10.1953 8.63347 10.1953 7.36714 9.41421 6.58609C8.63316 5.80504 7.36684 5.80504 6.58579 6.58609C5.80474 7.36714 5.80474 8.63347 6.58579 9.41452Z"
                          fill="#20409B"
                        />
                        <ellipse
                          cx="2.66682"
                          cy="7.99983"
                          rx="1.99983"
                          ry="1.99983"
                          fill="#20409B"
                        />
                        <path
                          d="M10 2.66602C10 3.77059 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 3.77059 6 2.66602C6 1.56145 6.89543 0.666016 8 0.666016C9.10457 0.666016 10 1.56145 10 2.66602Z"
                          fill="#20409B"
                        />
                        <path
                          d="M10 13.334C10 14.4386 9.10457 15.334 8 15.334C6.89543 15.334 6 14.4386 6 13.334C6 12.2294 6.89543 11.334 8 11.334C9.10457 11.334 10 12.2294 10 13.334Z"
                          fill="#20409B"
                        />
                        <ellipse
                          cx="13.3328"
                          cy="7.99983"
                          rx="1.99983"
                          ry="1.99983"
                          fill="#20409B"
                        />
                      </svg>
                    </p>
                    <p class="robo-12-400 txt-grey-900 mb-0 max-line-1">{{ mem.role_lang }}</p>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div v-if="isAdmin" class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
            <a
              v-for="role in roles"
              :key="role.id"
              class="dropdown-item"
              :class="checkCurrentRole(mem,role)&&'bg-green-200'"
              href="javascript:;"
              @click="assignMember(mem,role.name)"
            >{{ role.name_lang}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'
import mdtUtils from '../../../utils/mdtUtils'

export default {
  name: 'CircleLiveMembers',
  props: {
    circleData: Object,
    isAdmin: Boolean
  },
  data () {
    return {
      members: [],
      mdtUtils,
      isLoading: false,
      pagination: {
        current_page: 1,
        per_page: 20
      },
      allMembers: [],
      roles: []
    }
  },
  watch: {
    circleData: {
      deep: true,
      handler () {
        this.handlePrepare()
      }
    }
  },
  created () {
    this.handlePrepare()
  },

  methods: {
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    async handlePrepare () {
      await this.getListRolesInRoom()
      await this.getCircleAllMembers()
    },
    getMemberRoleName (data) {
      return typeof data === 'string' && appUtils.isJSON(data)
        ? JSON.parse(data)['livestream']
        : typeof data === 'object'
          ? data['livestream']
          : data
    },
    async getCircleAllMembers () {
      let self = this

      if (!self.circleData?.id) return

      try {
        self.isLoading = true

        let params = {
          page: 1,
          status: 2,
          page_num: 1,
          page_size: 1000,
          circle_id: self.circleData?.id
        }

        await self.$rf
          .getRequest('DoctorRequest')
          .getLiveCircleMembers(params)
          .then(r => {
            let members = r.data
            // eslint-disable-next-line no-unused-expressions
            members?.data?.forEach(mem => {
              if (!mem.one_hundred_ms_role) {
                const role = {
                  livestream: 'viewer'
                }
                mem.one_hundred_ms_role = JSON.stringify(role)
              }
              mem.user = mem?.user_info
            })
            const memberResponse = members?.data?.map(item => {
              const role = this.getMemberRoleName(item.one_hundred_ms_role)

              const role_lang = role
                ? this.$t(`circle_feature.${appUtils.convertRolesName(role)}`)
                : ''

              return { ...item, role_lang }
            })

            self.members = [...memberResponse]
          })
          .catch(e => {
            console.log(e)
            self.$toast.open({
              message: self.$t('circle_feature.lbl_permission_msg'),
              type: 'warning'
            })
          })
          .finally(() => {
            self.isLoading = false
          })

        return
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getListRolesInRoom () {
      try {
        this.isLoading = true
        const type = 'livestream'

        await this.$rf
          .getRequest('DoctorRequest')
          .getListRolesInRoom(type)
          .then(res => {
            const roleArr = Object.keys(res.data.data[0].roles)
            this.roles = roleArr
              .map(role => {
                return {
                  ...res.data.data[0].roles[role],
                  name_lang: role
                    ? this.$t(
                      `circle_feature.${appUtils.convertRolesName(role)}`
                    )
                    : ''
                }
              })
              .filter(
                role =>
                  role.name !== '__internal_recorder' &&
                  role.name !== 'hls-viewer'
              )
          })

          .catch(() => {
            this.isLoading = false
          })
          .finally(() => {
            // this.isLoading = false
          })
      } catch (error) {
        console.log(error)
      }
    },
    async assignMember (member, role_name) {
      if (!member?.id) return

      const name = role_name
        ? this.$t(`circle_feature.${appUtils.convertRolesName(role_name)}`)
        : ''

      const r = await this.$confirm(
        this.$t('fm_confirm_that_you_want_to_update_role', {
          name: member?.user_info?.name,
          role: name
        }),
        {
          cancelButtonText: this.$t('lbl_cancel'),
          confirmButtonText: this.$t('lbl_confirm'),
          dangerouslyUseHTMLString: true
        }
      )
        .then(r => r)
        .catch(() => false)

      if (!r) return

      this.loading = true

      const circle_member_id = member.id

      const role = await this.setRoleData(circle_member_id, role_name)
      const params = {
        circle_member_id: circle_member_id,
        role: JSON.stringify(role)
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .assignConferenceRole(params)
        .then(r => {
          this.members.find(
            mem => mem.id === circle_member_id
          ).one_hundred_ms_role = { ...role }

          this.loading = false

          if (
            this.circleData?.created_by === this.$user?.id &&
            this.circle_member?.id === circle_member_id
          ) {
            this.circleMemberData = {
              ...this.circleMemberData,
              one_hundred_ms_role: appUtils.isJSON(
                r.data?.data?.one_hundred_ms_role
              )
                ? JSON.parse(r.data?.data?.one_hundred_ms_role)
                : r.data?.data?.one_hundred_ms_role
            }
          }

          this.getCircleAllMembers()
          this.$toast.open({
            message: this.$t('lbl_update_success'),
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error)
          this.$toast.open({
            message: this.$t('lbl_udpate_fail'),
            type: 'error'
          })
        })
    },
    async setRoleData (circle_member_id, role_name) {
      const memberData = this.members.find(mem => mem.id === circle_member_id)
      let one_hundred_ms_role = memberData?.one_hundred_ms_role
      one_hundred_ms_role =
        typeof one_hundred_ms_role === 'string' &&
        appUtils.isJSON(one_hundred_ms_role)
          ? one_hundred_ms_role
          : JSON.stringify(one_hundred_ms_role)
      const conditions = ['livestream', 'conference']
      const checkRole = conditions?.some(el =>
        one_hundred_ms_role?.includes(el)
      )
      const currentRoles = checkRole ? JSON.parse(one_hundred_ms_role) : {}
      const role = {
        ...currentRoles,
        livestream: role_name
      }
      return role
    },
    checkCurrentRole (member, role) {
      const memberRole = this.getMemberRoleName(member.one_hundred_ms_role)
      return memberRole === role.name
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  height: 100% !important;
}
.flatpickr-calendar.open {
  background-color: #e4e4e4 !important;
}
.select-date {
  color: black !important;
  width: 100%;
  max-width: 237px;
  height: 47px;
}
.search-disease {
  width: 339px;
  height: 47px;
}
.item-doctor {
  height: 47px;
  cursor: pointer;
  margin-bottom: 16px;
  padding-left: 5px;
}
.item-disease {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item-disease-right {
  width: 130px;
}
.item-disease-left {
  width: calc(100% - 130px);
  display: flex;
  align-items: center;
}
.btn-add-someting {
  color: #6d6e6f;
  border: dashed 1.5px #d3d3d3;
  border-radius: 14px;
  height: 47px;
  cursor: pointer;
  margin-bottom: 16px;
}
.mdt-detail-body {
  position: relative;
  height: calc(100vh - 100px);
  overflow-y: auto !important;
  overflow-x: hidden;
  border-radius: 10px;
  padding-bottom: 50px;
  padding-right: 32px;
}
.item-session {
  display: flex;
  align-items: center;
  height: 136px;
}
.h-136px {
  height: 136px;
}

.form-select-arrow {
  background-image: url(../../../../public/assets/images/icon/sort_down.png);
  background-size: 16px;
  background-position: left center;
  background-repeat: no-repeat;
}
</style>